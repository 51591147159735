import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const CapacitacionesTemplate = ({
  content,
  contentComponent,
  theme,
  title,
  helmet,
  urlcapacitacion,
  descripcion,
  tipocapacitacion,
  date,
  organizadores,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <section className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <p><strong>Tema:</strong> {theme}</p>
            <p><strong>Tipo de capacitación:</strong> {tipocapacitacion}</p>
            <p><strong>Fecha:</strong> {date}</p>
            <p><strong>Organizadores:</strong> {organizadores}</p>
            <p><strong>Descripción:</strong>{descripcion}</p>
            <a href={urlcapacitacion} target="_blank" rel="noopener noreferrer">Link a la capacitación</a>
            {/* <PostContent content={content} /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

CapacitacionesTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  theme: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  urlcapacitacion: PropTypes.string,
  tipocapacitacion: PropTypes.string,
  descripcion: PropTypes.string,
  date: PropTypes.string,
  organizadores: PropTypes.string,
};

const Capacitaciones = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <CapacitacionesTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Capacitación">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.theme}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        theme={post.frontmatter.theme}
        urlcapacitacion={post.frontmatter.urlcapacitacion}
        tipocapacitacion={post.frontmatter.tipocapacitacion}
        date={post.frontmatter.date}
        organizadores={post.frontmatter.organizadores}
        descripcion={post.frontmatter.descripcion}
      />
    </Layout>
  );
};

Capacitaciones.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default Capacitaciones;

export const pageQuery = graphql`
  query CapacitacionesByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(
          formatString: "MMMM DD, YYYY",
          locale: "es-ES"
          )
        title
        theme
        tipocapacitacion
        organizadores
        urlcapacitacion
        descripcion
      }
    }
  }
`;
